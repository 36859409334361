import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PublicRoutes } from './PublicRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import { DashboardRoutes } from './DashboardRoutes'
import LoadingPage from '@/pages/Loading'
import Providers from '@/providers/Providers'
import { RoleValidator } from './RoleValidator'
import { PublicRoutesList } from './routerList'
import { rolesType } from '@/constants'

const LoginPage = lazy(() => import('../pages/auth/Login'))

export const AppRouters = () => {
  const { loggedIn, rol } = useSelector(state => state.auth)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={PublicRoutesList.LOGIN}
          element={
            <PublicRoutes isAuth={!loggedIn}>
              <Suspense fallback={<LoadingPage />}>
                <LoginPage />
              </Suspense>
            </PublicRoutes>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoutes isAuth={!!loggedIn}>
              <RoleValidator>
                {(rol.includes(rolesType.CAJA) ||
                  rol.includes(rolesType.DOCTOR_EXTERNO) ||
                  rol.includes(rolesType.DOCTOR) ||
                  rol.includes(rolesType.ADMIN)) && <Providers />}
                <DashboardRoutes />
              </RoleValidator>
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
