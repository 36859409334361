import Axios from 'axios'
import { historyBackup } from '@/services'
import { authHeader, authUser } from '@/helpers'
import { cleanLocalStorage } from '@/utils'
import { PublicRoutesList } from '@/routers/routerList'
import { showNotify } from '@/providers/Notify'

const handleLogs = config => {
  if (config?.data) {
    historyBackup.limitRecords({
      time: new Date().toString(),
      data: config.data,
      url: config.url,
      method: config.method,
      baseURL: config.baseURL,
      user: authUser() || 'sin usuario'
    })
  }
}

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API,
  withCredentials: false,
  credentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 1000 * 60
  // signal: AbortSignal.timeout(1000 * 30)
})

axios.interceptors.request.use(
  config => {
    if (import.meta.env.VITE_PROD === 'production') {
      handleLogs(config)
    }

    if (authHeader()) {
      config.headers.Authorization = authHeader()
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  config => config,
  error => {
    if (
      error.response?.data?.message === 'Unauthorized' ||
      error.response?.data?.message?.includes('caducado') ||
      error.response?.data?.status === 401 ||
      error.response?.data?.statusCode === 401
    ) {
      cleanLocalStorage()
      window.location.href = PublicRoutesList.LOGIN
    }
    if (error?.message === 'canceled') {
      return Promise.reject(error)
    }

    showNotify.error(error)
    return Promise.reject(error)
  }
)

export default axios
export { axios }
