import { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'

import HemodialysisSessionLayout from '.'
import LoadingPage from '../Loading'
import { PrivateRouteList } from '@/routers/routerList'

const HemodialysisSessionForm = lazy(() => import('@/pages/hemodialysis/HemodialysisSessionForm'))
const HemodialysisSessionPDF = lazy(() => import('@/pages/hemodialysis/HemodialysisSessionPDF.pdf'))
const TabPanelSesion = lazy(() => import('@/pages/hemodialysis/TabPanelSession'))
const TabPanelEvolution = lazy(() => import('@/pages/hemodialysis/TabPanelEvolution'))
const TabPanelMedicines = lazy(() => import('@/pages/hemodialysis/TabPanelMedicines'))
const PageSampleResult = lazy(() =>
  import('@/components/laboratory/tab_sample_result').then(module => ({
    default: module.PageSampleResult
  }))
)
const TabImagingHemodialysis = lazy(() => import('@/pages/hemodialysis/components/ImagingHemodialisis'))
const PageLaboratories = lazy(() =>
  import('@/pages/laboratory/views').then(module => ({
    default: module.TabLaboratories
  }))
)
const HemodialysisTurns = lazy(() => import('@/pages/hemodialysis/HemodialysisTurns'))
const PatientsHemodialysis = lazy(() => import('@/pages/hemodialysis/PatientsHemodialysis'))

export const routerHemodialysis = (
  <Route path={PrivateRouteList.HEMODIALYSIS.ROOT} element={<HemodialysisSessionLayout />}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelSesion />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.HEMODIALYSIS.CLINICAL_REPORT}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelEvolution />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.HEMODIALYSIS.MEDICINES}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelMedicines />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.HEMODIALYSIS.LABORATORIES}
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageSampleResult hemo />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.HEMODIALYSIS.QUICK_VIEW_LABORATORIES}
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageLaboratories />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.HEMODIALYSIS.IMAGENOLOGY}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabImagingHemodialysis />
        </Suspense>
      }
    />
    <Route path={PrivateRouteList.HEMODIALYSIS.FORM}>
      <Route
        index
        element={
          <Suspense fallback={<LoadingPage />}>
            <HemodialysisSessionForm />
          </Suspense>
        }
      />
      <Route
        path={PrivateRouteList.HEMODIALYSIS.SESSION_PDF}
        element={
          <Suspense fallback={<LoadingPage />}>
            <HemodialysisSessionPDF />
          </Suspense>
        }
      />
    </Route>
    <Route
      path={PrivateRouteList.HEMODIALYSIS.SESSION_PDF}
      element={
        <Suspense fallback={<LoadingPage />}>
          <HemodialysisSessionPDF />
        </Suspense>
      }
    />
  </Route>
)

export const routerHemodialysisTurns = (
  <Route path={PrivateRouteList.HEMODIALYSIS.TURNS}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <HemodialysisTurns />
        </Suspense>
      }
    />
    <Route path={PrivateRouteList.HEMODIALYSIS.PATIENTS}>
      <Route
        index
        element={
          <Suspense fallback={<LoadingPage />}>
            <PatientsHemodialysis />
          </Suspense>
        }
      />
      <Route path={PrivateRouteList.HEMODIALYSIS.SESSIONS}>
        <Route
          index
          element={
            <Suspense fallback={<LoadingPage />}>
              <TabPanelSesion back={true} />
            </Suspense>
          }
        />
        <Route path={PrivateRouteList.HEMODIALYSIS.FORM}>
          <Route
            index
            element={
              <Suspense fallback={<LoadingPage />}>
                <HemodialysisSessionForm />
              </Suspense>
            }
          />
          <Route
            path={PrivateRouteList.HEMODIALYSIS.SESSION_PDF}
            element={
              <Suspense fallback={<LoadingPage />}>
                <HemodialysisSessionPDF />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={PrivateRouteList.HEMODIALYSIS.SESSION_PDF}
          element={
            <Suspense fallback={<LoadingPage />}>
              <HemodialysisSessionPDF />
            </Suspense>
          }
        />
      </Route>
    </Route>
  </Route>
)
