import * as yup from 'yup'

export const categorySchema = yup.object().shape({
  name: yup.string().nullable().typeError('Dato incorrecto').required('Campo requerido'),
  description: yup.string(),
  active: yup.boolean()
})

export const INITIAL_CATEGORY = {
  name: '',
  description: '',
  active: true
}
