import * as yup from 'yup'

export const messageScheme = yup.object().shape({
  number: yup
    .string()
    .required('Campo requerido')
    .min(4, 'Campo requerido')
    .nullable()
    .typeError('Dato incorrecto'),
  message: yup
    .string()
    .nullable()
    .typeError('Dato incorrecto')
    .required('Campo requerido')
})

export const INITIAL_MESSAGE_SCHEMA = {
  number: '591',
  message: ''
}
