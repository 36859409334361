import { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'

import { QueryActions } from '@/components'
import {
  CardAddmissionDetail,
  CardBedAssigneds,
  CardMedicalHistory,
  CardPatientAdmissionDetail,
  CardTraitingDoctor,
  CardTriageLevel,
  DrawerRightAddmission,
  ModalTriageLevel
} from '../components'
import { internService } from '@/services'
import { showNotify } from '@/providers/Notify'
import { useGlobalContext } from '@/contexts'
import { PrivateRouteList } from '@/routers'

export const LayoutFormDetail = () => {
  const { admissionId } = useParams()
  const [open, setOpen] = useState(false)
  const { setLoading } = useGlobalContext()
  const navigate = useNavigate()

  const queryAdmissionDetail = useQuery({
    queryKey: ['get-admission-form-detail', { admissionId }],
    queryFn: () => internService.getTemplateAdmissionFormById(admissionId),
    enabled: !!admissionId
  })

  const handleAssignedBed = async data => {
    try {
      setLoading(true)
      const model = {
        admissionFormId: admissionId,
        bedId: data.bed.id,
        areaId: data.area.id
      }
      await internService.patchAssignedBed(model).then(() => {
        showNotify.success('Guardado')
        queryAdmissionDetail.refetch()
        setOpen(false)
      })
    } catch (e) {
      showNotify.error(e)
    } finally {
      setLoading(false)
    }
  }

  const hanldeEpicrisis = epicrisis => {
    if (!epicrisis) {
      navigate(PrivateRouteList.EMERGENCY.EPICRISIS)
      return
    }
    navigate(PrivateRouteList.EMERGENCY.EPICRISIS + '/' + epicrisis.id)
  }

  const handleDocuments = () => {
    navigate(PrivateRouteList.EMERGENCY.DOCUMENT_LIST)
  }

  const handleEditTriageLevel = async data => {
    try {
      setLoading(true)
      const model = {
        triageLevelId: data?.triageLevel?.id
      }
      await internService.patchAdissionForm(admissionId, model).then(() => {
        showNotify.success('Guardado')
        queryAdmissionDetail.refetch()
        setOpen(false)
      })
    } catch (e) {
      showNotify.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 4, md: 3 }}>
        <QueryActions query={queryAdmissionDetail} />
        <DrawerRightAddmission addmissionDetail={queryAdmissionDetail?.data}>
          <CardPatientAdmissionDetail patient={queryAdmissionDetail?.data?.patient} />
          <Button onClick={handleDocuments}>Notas</Button>

          <CardTriageLevel
            triageLevel={queryAdmissionDetail?.data?.triageLevel}
            handleEditTriage={() => {
              setOpen({ triageLevel: true })
            }}
          />
          <CardAddmissionDetail
            handleEditBed={() => {
              setOpen({ bed: true })
            }}
            addmissionDetail={queryAdmissionDetail?.data}
          />
          <CardTraitingDoctor treatingDoctor={queryAdmissionDetail?.data?.treatingDoctor} />
          <CardMedicalHistory admissionId={queryAdmissionDetail?.data?.id} patient_id={queryAdmissionDetail?.data?.patient?.id} />
          <Button onClick={() => hanldeEpicrisis(queryAdmissionDetail?.data?.epicrisis)}>Epicrisis</Button>
        </DrawerRightAddmission>
      </Grid>
      <Grid size={{ xs: 12, sm: 8, md: 9 }}>
        <Outlet />
      </Grid>
      <CardBedAssigneds open={!!open?.bed} setOpen={setOpen} handleAssignedBed={handleAssignedBed} />
      <ModalTriageLevel open={!!open?.triageLevel} setOpen={setOpen} handleEditTriageLevel={handleEditTriageLevel} />
    </Grid>
  )
}
