import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'

import DialogSesion from '@/components/DialogSesion'
import { CustomToolbar } from './components/CustomToolbar'
import { drawerWidth } from './components/ItemsList'
import { DrawerList } from './components/DrawerList'
import { UploadFilesMinio } from '@/components/UploadFilesMinio'
import { ButtonDarkMode } from '@/components/ButtonDarkMode'
import UserButtonMenu from './components/UserButtonMenu'
import { idleTimeExpired } from '@/store/auth'
import { rolesType } from '@/constants'
import { updateDrawerLeft } from '@/store/config'
import { NetworkStatus } from '@/components'

function MainLayout(props) {
  const { window } = props
  const { user, idle, rol } = useSelector(item => item.auth)
  const { drawerLeft } = useSelector(item => item.config)

  const [expired, setExpired] = useState(false)
  const dispatch = useDispatch()
  const container =
    window !== undefined ? () => window().document.body : undefined

  const onIdle = () => {
    setExpired(
      rol.includes(rolesType.DOCTOR) || rol.includes(rolesType.DOCTOR_EXTERNO)
    )
    dispatch(
      idleTimeExpired(
        rol.includes(rolesType.DOCTOR) || rol.includes(rolesType.DOCTOR_EXTERNO)
      )
    )
  }

  // TODO: X * X * min
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle
  })

  useEffect(() => {
    if (idle) setExpired(true)
  }, [idle])

  const handleDrawerToggle = () => {
    dispatch(updateDrawerLeft(!drawerLeft))
  }

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    dispatch(updateDrawerLeft(open))
  }

  return (
    <>
      {expired && (
        <DialogSesion open={expired} handleClose={() => setExpired(false)} />
      )}
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          elevation={0}
          enableColorOnDark
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        >
          <CustomToolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Stack direction="row" alignItems="center" spacing={1}>
              <UserButtonMenu user={user} rol={rol} />
              <ButtonDarkMode />
              <NetworkStatus rounded />
            </Stack>
          </CustomToolbar>
        </AppBar>
        <Box
          component="nav"
          aria-label="folders"
          sx={{ flexShrink: { md: 0 } }}
        >
          <Drawer
            elevation={0}
            container={container}
            variant="temporary"
            open={drawerLeft}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth
              }
            }}
          >
            <CustomToolbar />
            <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              <DrawerList />
            </Box>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{ height: '100vh', width: '100%', pt: 7, px: 1 }}
        >
          <Outlet />
          <UploadFilesMinio />
        </Box>
      </Box>
    </>
  )
}

export default MainLayout
