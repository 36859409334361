import * as yup from 'yup'

export const subLaboratorySchema = yup.object().shape({
  product: yup.object().required('Campo requerido'),
  name: yup.string().required('Campo requerido'),
  reference_value: yup.object().required('Campo requerido'),
  category: yup.object().required('Campo requerido')
})

export const INITIAL_SUB_LABORATORY = {
  product: null,
  name: '',
  reference_value: null,
  category: null
}
