import FilePresentIcon from '@mui/icons-material/FilePresent'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { fileService } from '@/services'
import { useGlobalContext } from '@/contexts'

const handleDisplayData = async (data, url) => {
  if (data) {
    const base64Response = await fetch(url.type + data)
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)

    if (
      url.type.toString().includes('jpg') ||
      url.type.toString().includes('png') ||
      url.type.toString().includes('jpeg') ||
      url.type.toString().includes('webp') ||
      url.type.toString().includes('gif') ||
      url.type.toString().includes('pdf')
    ) {
      window.open(blobUrl)
    } else {
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = url?.description || ''
      a.click()
    }
  }
}

const handleLocalAttached = url => {
  const edit = `${url.filename}`
  if (edit.includes('data:')) {
    const resp = edit.split(',')
    return {
      filename: resp[1],
      type: `${resp[0]},`,
      local: true
    }
  }
  return {
    ...url,
    local: false
  }
}

function ButtonShowFile({ url, button = false }) {
  const { setLoading } = useGlobalContext()
  const handleFileShow = () => {
    const newUrl = handleLocalAttached(url.toString().includes('data:') ? { filename: url } : url)

    if (!newUrl.local) {
      setLoading(true)
      fileService
        .getFile(newUrl.filename)
        .then(data => {
          handleDisplayData(data, newUrl)
        })
        .finally(() => setLoading(false))
    } else {
      handleDisplayData(newUrl.filename, newUrl)
    }
  }
  return (
    <Tooltip arrow title={url?.description || 'Archivo'}>
      {button ? (
        <Button
          sx={{ my: 1 / 2 }}
          variant="outlined"
          color="primary"
          onClick={handleFileShow}
          startIcon={<FilePresentIcon fontSize="small" />}
        >
          <Typography
            variant="caption"
            sx={{
              maxWidth: '100px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {url?.description || 'archivo'}
          </Typography>
        </Button>
      ) : (
        <IconButton onClick={handleFileShow}>
          <FilePresentIcon fontSize="small" color="primary" />
        </IconButton>
      )}
    </Tooltip>
  )
}

export default ButtonShowFile
