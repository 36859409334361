export const IMAGENOLOGIA = 'Imagenologia'
export const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']

export const WITH_QR = ''

export const SocketKeys = {
  session: 'session'
}

export const cashierConstants = (() => {
  const otro = 'otro'
  const devolucion = 'devolucion'
  const pagoDoctores = 'pago doctores'

  function validateOther(text = '') {
    return text.toLowerCase().includes(otro)
  }

  function validateDevolution(text = '') {
    return text.toLowerCase().includes(devolucion)
  }

  function validatePayDoctor(text = '') {
    return text.toLowerCase().includes(pagoDoctores)
  }

  return {
    otro,
    devolucion,
    pagoDoctores,
    validateOther,
    validateDevolution,
    validatePayDoctor
  }
})()

export const referenceValueType = (() => {
  const REACTIVO = 'REACTIVO'
  const CONTEO = 'CONTEO'
  const OTRO = 'OTRO'

  const TRASLATE = {
    [REACTIVO]: 'Reactivo',
    [CONTEO]: 'Conteo',
    [OTRO]: 'Otro'
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  return {
    REACTIVO,
    CONTEO,
    OTRO,
    TRASLATE,
    traslate
  }
})()

export const patientType = (() => {
  const INTERNO = 'INTERNO'
  const EXTERNO = 'EXTERNO'

  const TRASLATE = {
    [INTERNO]: 'Interno',
    [EXTERNO]: 'Externo'
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  return {
    INTERNO,
    EXTERNO,
    TRASLATE,
    traslate
  }
})()

export const imagingType = (() => {
  const PENDIENTE = 'PENDIENTE'
  const TERMINADO = 'TERMINADO'
  const ANULADO = 'ANULADO'

  const COLOR_CLASS = {
    [ANULADO]: 'error',
    [PENDIENTE]: 'secondary',
    [TERMINADO]: 'primary'
  }

  const TRASLATE = {
    [PENDIENTE]: 'Pendiente',
    [TERMINADO]: 'Terminado',
    [ANULADO]: 'Anulado'
  }

  function colorClass(state) {
    return COLOR_CLASS[state] || undefined
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  return {
    PENDIENTE,
    TERMINADO,
    ANULADO,
    COLOR_CLASS,
    colorClass,
    TRASLATE,
    traslate
  }
})()

export const sampleType = (() => {
  const ENTREGADO = 'ENTREGADO'
  const PENDIENTE = 'PENDIENTE'
  const PROCESO = 'PROCESO'
  const TERMINADO = 'TERMINADO'
  const ANULADO = 'ANULADO'

  const TRASLATE = {
    [ENTREGADO]: 'Entregado',
    [PENDIENTE]: 'Pendiente',
    [PROCESO]: 'Proceso',
    [TERMINADO]: 'Terminado',
    [ANULADO]: 'Anulado'
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  return {
    ENTREGADO,
    PENDIENTE,
    PROCESO,
    TERMINADO,
    ANULADO,
    TRASLATE,
    traslate
  }
})()

export const paymentType = (() => {
  const ANULADO = 'ANULADO'
  const EFECTIVO = 'EFECTIVO'
  const TRANSFERENCIA = 'TRANSFERENCIA'

  const TRASLATE = {
    [ANULADO]: 'Anulado',
    [EFECTIVO]: 'Efectivo',
    [TRANSFERENCIA]: 'Transferencia'
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  return {
    ANULADO,
    EFECTIVO,
    TRANSFERENCIA,
    TRASLATE,
    traslate
  }
})()

export const genderType = (() => {
  const MASCULINO = 'MASCULINO'
  const FEMENINO = 'FEMENINO'

  const TRASLATE = {
    [MASCULINO]: 'Masculino',
    [FEMENINO]: 'Femenino'
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  function getAll() {
    return [MASCULINO, FEMENINO]
  }

  return {
    MASCULINO,
    FEMENINO,
    TRASLATE,
    traslate,
    getAll
  }
})()

export const stateMeetType = (() => {
  const RESERVADO = 'RESERVADO'
  const SIGNOS_VITALES = 'SIGNOS_VITALES'
  const ATENDIDO = 'ATENDIDO'
  const AUSENTE = 'AUSENTE'
  const REPROGRAMADO = 'REPROGRAMADO'
  const ELIMINADO = 'ELIMINADO'

  const TRASLATE = {
    [RESERVADO]: 'Reservado',
    [SIGNOS_VITALES]: 'Signos vitales realizado',
    [ATENDIDO]: 'Atendido',
    [AUSENTE]: 'Ausente',
    [REPROGRAMADO]: 'Reprogramado',
    [ELIMINADO]: 'Eliminado'
  }

  const COLOR_HEX = {
    RESERVADO: '#34ace0',
    SIGNOS_VITALES: '#a29bfe',
    ATENDIDO: '#00b894',
    AUSENTE: '#ff7979',
    REPROGRAMADO: '#9c88ff',
    ELIMINADO: '#ef5350'
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  function colorHex(state) {
    return COLOR_HEX[state] || undefined
  }

  return {
    RESERVADO,
    SIGNOS_VITALES,
    ATENDIDO,
    AUSENTE,
    REPROGRAMADO,
    ELIMINADO,
    TRASLATE,
    COLOR_HEX,
    traslate,
    colorHex
  }
})()

export const stateSalesType = (() => {
  const TODOS = ''
  const NO_PAGADO = 'NO_PAGADO'
  const PAGADO = 'PAGADO'
  const CREDITO = 'CREDITO'
  const ELIMINADO = 'ELIMINADO'
  const ANULADO = 'ANULADO'
  const INTERNACION = 'INTERNACION'

  const TRASLATE = {
    TODOS: 'Todos',
    [NO_PAGADO]: 'No pagado',
    [PAGADO]: 'Pagado',
    [CREDITO]: 'Credito',
    [ELIMINADO]: 'Eliminado',
    [ANULADO]: 'Anulado',
    [INTERNACION]: 'Internación'
  }

  const COLOR_HEX = {
    [NO_PAGADO]: '#ef5350',
    [PAGADO]: '#27ae60',
    [CREDITO]: '#03a9f4',
    [ELIMINADO]: '#ff7979',
    [ANULADO]: '#ff9800'
  }

  const COLOR_CLASS = {
    [NO_PAGADO]: 'error',
    [PAGADO]: 'success',
    [CREDITO]: 'info',
    [ELIMINADO]: 'secondary',
    [ANULADO]: 'warning'
  }

  function traslate(state) {
    return TRASLATE[state] || 'Sin estado'
  }

  function colorHex(state) {
    return COLOR_HEX[state] || undefined
  }

  function colorClass(state) {
    return COLOR_CLASS[state] || undefined
  }

  return {
    TODOS,
    NO_PAGADO,
    PAGADO,
    CREDITO,
    ELIMINADO,
    ANULADO,
    INTERNACION,
    TRASLATE,
    COLOR_HEX,
    COLOR_CLASS,
    traslate,
    colorHex,
    colorClass
  }
})()

export const stateVascularAccessType = (() => {
  const CATETER = 'CATETER'
  const FISTULA = 'FISTULA'

  const VASCULAR_ACCESS_WORDS = {
    [CATETER]: 'CATETER VENOSO',
    [FISTULA]: 'FISTULA ARTERIO VENOSA'
  }

  const TRASLATE = {
    [CATETER]: 'Cateter',
    [FISTULA]: 'Fistula'
  }

  function traslate(access) {
    return TRASLATE[access]
  }

  return {
    CATETER,
    FISTULA,
    VASCULAR_ACCESS_WORDS,
    TRASLATE,
    traslate
  }
})()

export const rolesType = (() => {
  const FARMACIA = 'FARMACIA'
  const ENFERMERA_HEMODIALISIS = 'ENFERMERA_HEMODIALISIS'
  const DOCTOR_HEMODIALISIS = 'DOCTOR_HEMODIALISIS'
  const IMAGENOLOGIA = 'IMAGENOLOGIA'
  const ENFERMERA = 'ENFERMERA'
  const BIOQUIMICA = 'BIOQUIMICA'
  const DOCTOR = 'DOCTOR'
  const DOCTOR_EXTERNO = 'DOCTOR_EXTERNO'
  const LABORATORIO = 'LABORATORIO'
  const REGISTRO = 'REGISTRO'
  const CAJA = 'CAJA'
  const PACIENTE = 'PACIENTE'
  const ADMIN = 'ADMIN'

  return {
    FARMACIA,
    ENFERMERA_HEMODIALISIS,
    DOCTOR_HEMODIALISIS,
    IMAGENOLOGIA,
    ENFERMERA,
    BIOQUIMICA,
    DOCTOR,
    DOCTOR_EXTERNO,
    LABORATORIO,
    REGISTRO,
    CAJA,
    PACIENTE,
    ADMIN
  }
})()

export const signatureType = {
  FIRMA: 'FIRMA',
  SELLO: 'SELLO'
}

export const searchParamNames = {
  patientId: 'patientId',
  saleId: 'saleId',
  sessionId: 'sessionId',
  turnId: 'turnId',
  imagingDetailId: 'imagingDetailId',
  from: 'from',
  to: 'to',
  search: 'search',
  id: 'id',
  diagnostic: 'diagnostic'
}

export const whatsAppType = {
  all: '',
  onlyUsers: 'onlyUsers',
  onlyGroups: 'onlyGroups',
  chat: 'chat',
  grupo: 'grupo'
}

export const physicalExamType = (() => {
  const CONSTITUCIONAL = 'CONSTITUCIONAL'
  const OJOS = 'OJOS'
  const BUCOFARINGEO = 'BUCOFARINGEO'
  const CUELLO = 'CUELLO'
  const CARDIOVASCULAR = 'CARDIOVASCULAR'
  const PULMONAR = 'PULMONAR'
  const ABDOMEN = 'ABDOMEN'
  const GENITOURINARIO = 'GENITOURINARIO'
  const EXTREMIDADES = 'EXTREMIDADES'
  const PIEL = 'PIEL'
  const NEUROLOGICO = 'NEUROLOGICO'

  function getAll() {
    return [
      CONSTITUCIONAL,
      OJOS,
      BUCOFARINGEO,
      CUELLO,
      CARDIOVASCULAR,
      PULMONAR,
      ABDOMEN,
      GENITOURINARIO,
      EXTREMIDADES,
      PIEL,
      NEUROLOGICO
    ]
  }

  return {
    CONSTITUCIONAL,
    OJOS,
    BUCOFARINGEO,
    CUELLO,
    CARDIOVASCULAR,
    PULMONAR,
    ABDOMEN,
    GENITOURINARIO,
    EXTREMIDADES,
    PIEL,
    NEUROLOGICO,
    getAll
  }
})()

export const waterBalanceType = (() => {
  const ORAL = 'ORAL'
  const PARENTERAL = 'PARENTERAL'
  const DREN = 'DREN'
  const SNG = 'SNG'
  const ORINA = 'ORINA'
  const OTRO = 'OTRO'

  function getAll() {
    return [ORAL, PARENTERAL, DREN, SNG, ORINA, OTRO]
  }

  return {
    ORAL,
    PARENTERAL,
    DREN,
    SNG,
    ORINA,
    OTRO,
    getAll
  }
})()

export const liquidAdministrationType = (() => {
  const ADMINISTRADO = 'ADMINISTRADO'
  const ELIMINADO = 'ELIMINADO'

  function getAll() {
    return [ADMINISTRADO, ELIMINADO]
  }

  return {
    ADMINISTRADO,
    ELIMINADO,
    getAll
  }
})()

export const frequencyType = (() => {
  const MES = 'MES'
  const SEMANA = 'SEMANA'
  const DIA = 'DIA'
  const HORA = 'HORA'
  const MINUTO = 'MINUTO'

  function getAll() {
    return [MES, SEMANA, DIA, HORA, MINUTO]
  }

  return {
    MES,
    SEMANA,
    DIA,
    HORA,
    MINUTO,
    getAll
  }
})()

export const durationType = (() => {
  const YEAR = 'YEAR'
  const MONTH = 'MONTH'
  const WEEK = 'WEEK'
  const DAY = 'DAY'
  const HOUR = 'HOUR'
  const MINUTE = 'MINUTE'

  const TRASLATE = {
    [YEAR]: 'AÑO',
    [MONTH]: 'MES',
    [WEEK]: 'SEMANA',
    [DAY]: 'DIA',
    [HOUR]: 'HORA',
    [MINUTE]: 'MINUTO'
  }

  const TRASLATE_INV = {
    AÑO: YEAR,
    MES: MONTH,
    SEMANA: WEEK,
    DIA: DAY,
    HORA: HOUR,
    MINUTO: MINUTE
  }

  function traslate(state) {
    return TRASLATE[state] || ''
  }

  function traslateInv(state) {
    return TRASLATE_INV[state] || ''
  }

  function getAll() {
    return [YEAR, MONTH, WEEK, DAY, HOUR, MINUTE]
  }

  function getAllTraslate() {
    return Object.values(TRASLATE)
  }

  return {
    YEAR,
    MONTH,
    WEEK,
    DAY,
    HOUR,
    MINUTE,
    TRASLATE,
    TRASLATE_INV,
    traslate,
    traslateInv,
    getAll,
    getAllTraslate
  }
})()

export const typeMedicalOrder = (() => {
  const TREATMENT = 'TREATMENT'
  const LABORATORY = 'LABORATORY'
  const IMAGING = 'IMAGING'
  const PHYSICAL_EXAM = 'PHYSICAL_EXAM'
  const VITAL_SIGN = 'VITAL_SIGN'
  const MEDICINE = 'MEDICINE'
  const SURGICAL = 'SURGICAL'
  const CONSULTATION = 'CONSULTATION'
  const TODOS = 'TODOS'

  const TRASLATE = {
    [TREATMENT]: 'Tratamiento',
    [LABORATORY]: 'Laboratorio',
    [IMAGING]: 'Estudio complementario',
    [PHYSICAL_EXAM]: 'Examen físico',
    [VITAL_SIGN]: 'Signos vitales',
    [MEDICINE]: 'Medicamento',
    [CONSULTATION]: 'Consulta',
    [SURGICAL]: 'Cirugía'
  }

  const traslate = state => {
    return TRASLATE[state] || ''
  }

  const getAll = () => {
    return Object.keys(TRASLATE)
  }

  return {
    TREATMENT,
    LABORATORY,
    IMAGING,
    PHYSICAL_EXAM,
    VITAL_SIGN,
    MEDICINE,
    SURGICAL,
    TRASLATE,
    CONSULTATION,
    TODOS,
    traslate,
    getAll
  }
})()
