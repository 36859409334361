import { configureStore } from '@reduxjs/toolkit'
import auth from './auth'
import darkMode from './dark-mode'
import reducerDoctors from './doctors'
import reducerLaboratories from './laboratories'
import reducerMeet from './meet'
import reducerProducts from './products'
import reducerSale from './sales'
import reducerShopping from './cart'
import reducerTemplate from './template'
import imagingDetail from './imaging-detail'
import uploadFile from './upload-file'
import notify from './notifications'
import config from './config'

const store = configureStore({
  reducer: {
    auth,
    darkMode,
    reducerDoctors,
    reducerLaboratories,
    reducerMeet,
    reducerProducts,
    reducerSale,
    reducerShopping,
    reducerTemplate,
    imagingDetail,
    uploadFile,
    notify,
    config
  }
})

export default store
