import moment from 'moment'

export const processDate = dateString => {
  let date

  if (!dateString) {
    date = moment()
    return date
  } else {
    date = moment(dateString)
  }

  return {
    // Formateo básico
    convertMoment: date,
    formattedDate: date.format('L'), // 10/31/2024
    formattedTime: date.format('LTS'), // 9:58:49 AM
    fullDateTime: date.format('LLL'), // Fecha completa legible
    formattedShort: date.format('DD/MM/YYYY HH:mm'), // Fecha completa legible

    // Operaciones con la fecha
    addDays: days => date.clone().add(days, 'days').format('LLL'), // Agregar días
    subtractDays: days => date.clone().subtract(days, 'days').format('LLL'), // Restar días
    // Operaciones con la hora
    addHours: hours => date.clone().add(hours, 'hours').format('DD/MM/YYYY HH:mm'), // Agregar horas
    subtractHours: hours => date.clone().subtract(hours, 'hours').format('DD/MM/YYYY HH:mm'), // Restar horas

    // Información sobre la fecha
    startOfMonth: date.clone().startOf('month').format('LLL'),
    endOfMonth: date.clone().endOf('month').format('LLL'),
    dayOfWeek: date.format('dddd'), // Día de la semana
    isWeekend: date.day() === 6 || date.day() === 0, // Comprobar si es fin de semana

    // Diferencia en tiempo
    timeAgo: date.fromNow(), // hasta hoy
    daysUntilNow: moment().diff(date, 'days'), // Días hasta hoy
    hoursUntilNow: moment().diff(date, 'hours'), // Horas hasta hoy

    // Conversion
    toISOString: date.utc().format()
  }
}

// Ejemplo de uso
// const dateInfo = processDate('2024-10-31T05:40:19.871Z');
// console.log(dateInfo);
